import React, {useRef, useState} from 'react';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';

import defaultThumbnail from '../../images/defaultThumbnail.png';
import allowed, {ENVR_ADMIN} from '../../util/allowed';
import withSnackbar from '../snackbarSupport';
import {getApplianceUrl} from '../../../api/appliances';
import {updateTamperImgRef} from '../../../api/cameras';

const TamperPanel = (props) => {
  const {children, value, index, classes, ...other} = props;
  return (
    <Box hidden={value !== index} {...other} id="1">
      {value === index && <Box id="tamper-content">{children}</Box>}
    </Box>
  );
};

const Thumbnail = ({title, imgSrc, loading, imgFallback, onLoad}) => {
  return (
    <Box className="thumbnail">
      <Box
        sx={{
          width: 320,
          border: '1px solid gainsboro',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Typography sx={{p: 1}}>{title}</Typography>
      </Box>
      <Box sx={{width: 320}} className="thumbnail-img">
        {loading ? (
          <Box sx={{height: 248}}>
            <CircularProgress size={70} sx={{mt: '25%', ml: '40%'}} />
          </Box>
        ) : (
          <img
            src={imgSrc}
            alt=""
            width="320"
            height="240"
            onLoad={() => {
              if (imgSrc !== defaultThumbnail) {
                onLoad(true);
              }
            }}
            onError={imgFallback}
          />
        )}
      </Box>
    </Box>
  );
};

const ImageRefPicker = ({
  isSmallScreen,
  refPickerTime,
  earliestDate,
  loading,
  preview,
  onChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen && 'column-reverse',
        alignItems: isSmallScreen && 'center',
      }}
    >
      <Box sx={{width: '320px', mr: !isSmallScreen && 3}} data-cy="tamper-text">
        <Typography sx={{mb: 2}}>
          Selecting a time will preview an image, which can then be set as the
          reference image.
        </Typography>
        <Box>
          {loading ? (
            <Typography sx={{mb: 3}}>Updating image...</Typography>
          ) : (
            <React.Fragment>
              {preview && (
                <Typography>
                  Click Update to set the new reference image.
                </Typography>
              )}

              {!preview && (
                <Typography>
                  No thumbnail available during this time period.
                </Typography>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
      <Box
        className="tamper-ref-picker"
        sx={{width: 320, mb: isSmallScreen && 2}}
      >
        <DateTimePicker
          value={refPickerTime}
          onChange={onChange}
          timeSteps={{hours: 1, minutes: 1, seconds: 1}}
          minDate={earliestDate}
          disableFuture
          slotProps={{
            actionBar: {actions: ['clear', 'cancel', 'accept']},
          }}
        />
      </Box>
    </Box>
  );
};

const CameraTamperModal = ({onClose, cameraData, currentUser, snackbar}) => {
  const {
    applianceId,
    hardwareId,
    hardwareName,
    hardwareStatus,
    displayMessage,
    earliestData,
    applianceSensorNum,
    timeZone,
  } = cameraData;
  const impaired = hardwareStatus === 'Impaired';
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const earliestDate = DateTime.fromISO(earliestData);
  const canUpdateRefImage = allowed(currentUser, [ENVR_ADMIN]);

  const defaultTime = DateTime.local()
    .setZone(timeZone)
    .set({second: 0, millisecond: 0});
  const [refPickerTime, setRefPickerTime] = useState(defaultTime);
  const [preview, setPreview] = useState(false);
  const [activePanel, setActivePanel] = useState('main');
  const [loading, setLoading] = useState(false);
  const previewNonce = useRef(0);

  const getThumbnailUrl = (timestamp) => {
    const imageRes = '320x-1';
    const time = timestamp ? `&start=${encodeURIComponent(timestamp)}` : '';

    const thumbnailUrl = `${getApplianceUrl(
      applianceId,
    )}/aapi/dvr/thumbnail?channel=${applianceSensorNum}${time}&resolution=${imageRes}&nonce=${
      previewNonce.current
    }`;

    previewNonce.current += 1;
    return thumbnailUrl;
  };

  const [mainImgSrc, setMainImgSrc] = useState(
    impaired ? `/api/v3/cameras/${hardwareId}/tamper_image` : getThumbnailUrl(),
  );
  const [previewImgSrc, setPreviewImgSrc] = useState(getThumbnailUrl());

  const dayImgDefault = `/api/v3/cameras/${hardwareId}/reference_images/day`;
  const nightImgDefault = `/api/v3/cameras/${hardwareId}/reference_images/night`;
  const [dayImgSrc, setDayImgSrc] = useState(dayImgDefault);
  const [nightImgSrc, setNightImgSrc] = useState(nightImgDefault);

  const handleTabChange = (newValue) => {
    if (newValue === activePanel) {
      setActivePanel('main');
    } else {
      setActivePanel(newValue);
    }
  };

  const handleImgRefUpdate = async (type) => {
    const useTamperImage = type === 'day' || type === 'night';
    const body = useTamperImage
      ? {use_tamper_image: true}
      : {start: refPickerTime.toISO()};
    const typeOfDay = useTamperImage ? type : activePanel;
    setPreview(false);
    setLoading(true);
    try {
      await updateTamperImgRef(typeOfDay, hardwareId, body);
      if (useTamperImage) {
        // forces image refresh
        setDayImgSrc(`${dayImgDefault}?`);
        setNightImgSrc(`${nightImgDefault}?`);
      }
      if (typeOfDay === 'day') {
        setDayImgSrc(dayImgDefault);
      } else {
        setNightImgSrc(nightImgDefault);
      }
      snackbar.success(
        'Successfully updated reference image. Please wait a moment for the image to update.',
      );
      setPreview(true);
    } catch (e) {
      snackbar.error(
        `Failed to update reference image. If this issue persists please contact support for assistance.`,
      );
    }
    setLoading(false);
  };

  const handleImgFallback = (thumbnailRef) => {
    if (thumbnailRef === 'preview') {
      setPreview(false);
      setPreviewImgSrc(defaultThumbnail);
    }
    if (thumbnailRef === 'main') {
      setMainImgSrc(defaultThumbnail);
    }
    if (thumbnailRef === 'day') {
      setDayImgSrc(defaultThumbnail);
    }
    if (thumbnailRef === 'night') {
      setNightImgSrc(defaultThumbnail);
    }
  };

  const DialogHeader = ({title}) => {
    const severity = impaired ? 'warning' : 'success';
    return (
      <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h5">{title}</Typography>
          <IconButton sx={{p: 0, mb: 1}} onClick={onClose}>
            <CloseIcon data-cy="close-button" />
          </IconButton>
        </Box>
        <Alert severity={severity} sx={{my: 1}} id="dialog-header">
          <AlertTitle>Camera: {hardwareName}</AlertTitle>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="body">Status: {hardwareStatus}</Typography>
            <Typography variant="body">
              Description: {displayMessage}
            </Typography>
          </Box>
        </Alert>
      </Box>
    );
  };

  return (
    <Box id="tamper-dialog">
      <DialogContent>
        <TamperPanel value={activePanel} index="main">
          <DialogHeader title="Image Quality Monitoring" />
          <Box
            sx={{
              display: 'flex',
              my: 2,
              flexDirection: isSmallScreen && 'column',
              alignItems: isSmallScreen && 'center',
            }}
          >
            <Box sx={{mr: !isSmallScreen && 3, mb: isSmallScreen && 2}}>
              <Thumbnail
                title="Current Day Reference Image"
                imgSrc={dayImgSrc}
                imgFallback={() => handleImgFallback('day')}
              />
              {canUpdateRefImage && (
                <Button
                  id="update-day-ref"
                  variant="contained"
                  onClick={() => handleTabChange('day')}
                  sx={{width: '100%'}}
                >
                  Update
                </Button>
              )}
            </Box>
            <Box>
              <Thumbnail
                title="Current Night Reference Image"
                imgSrc={nightImgSrc}
                imgFallback={() => handleImgFallback('night')}
              />
              {canUpdateRefImage && (
                <Button
                  id="update-night-ref"
                  variant="contained"
                  onClick={() => handleTabChange('night')}
                  sx={{width: '100%'}}
                >
                  Update
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box sx={{mt: !isSmallScreen && 1}}>
              <Thumbnail
                id="thumbnail"
                title={
                  impaired ? 'Impairment Detected' : 'Live Thumbnail Preview'
                }
                imgSrc={mainImgSrc}
                imgFallback={() => handleImgFallback('main')}
              />
              {canUpdateRefImage && impaired && (
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button
                    id="set-tamper-as-day"
                    variant="contained"
                    onClick={() => handleImgRefUpdate('day')}
                  >
                    Set As Day Ref
                  </Button>
                  <Button
                    id="set-tamper-as-night"
                    variant="contained"
                    onClick={() => handleImgRefUpdate('night')}
                  >
                    Set As Night Ref
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </TamperPanel>
        <TamperPanel value={activePanel} index="day">
          <DialogHeader title="Update Day Reference Image" />
          <Box
            sx={{
              display: 'flex',
              my: 2,
              flexDirection: isSmallScreen && 'column',
              alignItems: isSmallScreen && 'center',
            }}
          >
            <Box sx={{mr: !isSmallScreen && 3, mb: isSmallScreen && 2}}>
              <Thumbnail
                title="Day Reference Image"
                loading={loading}
                imgSrc={dayImgSrc}
                imgFallback={() => handleImgFallback('day')}
              />
            </Box>
            <Box>
              <Thumbnail
                title="Day Preview"
                imgSrc={previewImgSrc}
                imgFallback={() => handleImgFallback('preview')}
                onLoad={setPreview}
              />
            </Box>
          </Box>
          <ImageRefPicker
            isSmallScreen={isSmallScreen}
            refPickerTime={refPickerTime}
            setPreview={setPreview}
            setRefPickerTime={setRefPickerTime}
            earliestDate={earliestDate}
            loading={loading}
            preview={preview}
            onChange={(newTime) => {
              setRefPickerTime(newTime);
              setPreviewImgSrc(getThumbnailUrl(newTime));
            }}
          />
        </TamperPanel>
        <TamperPanel value={activePanel} index="night">
          <DialogHeader title="Update Night Reference Image" />
          <Box
            sx={{
              display: 'flex',
              my: 2,
              flexDirection: isSmallScreen && 'column',
              alignItems: isSmallScreen && 'center',
            }}
          >
            <Box sx={{mr: !isSmallScreen && 3, mb: isSmallScreen && 2}}>
              <Thumbnail
                title="Night Reference Image"
                loading={loading}
                imgSrc={nightImgSrc}
                imgFallback={() => handleImgFallback('night')}
              />
            </Box>
            <Box>
              <Thumbnail
                title="Night Preview"
                imgSrc={previewImgSrc}
                imgFallback={() => handleImgFallback('preview')}
                onLoad={setPreview}
              />
            </Box>
          </Box>
          <ImageRefPicker
            isSmallScreen={isSmallScreen}
            refPickerTime={refPickerTime}
            setPreview={setPreview}
            setRefPickerTime={setRefPickerTime}
            earliestDate={earliestDate}
            loading={loading}
            preview={preview}
            onChange={(newTime) => {
              setRefPickerTime(newTime);
              setPreviewImgSrc(getThumbnailUrl(newTime));
            }}
          />
        </TamperPanel>
      </DialogContent>
      <DialogActions id="tamper-actions" sx={{pt: 0}}>
        {canUpdateRefImage && activePanel !== 'main' && (
          <React.Fragment>
            <Button
              id="back"
              variant="text"
              onClick={() => handleTabChange('main')}
            >
              Back
            </Button>
            <Button
              id="update-allowed"
              onClick={handleImgRefUpdate}
              variant="contained"
              disabled={!preview}
            >
              Update
            </Button>
          </React.Fragment>
        )}

        {activePanel === 'main' && (
          <Button id="tamper-close" variant="text" onClick={onClose}>
            Close
          </Button>
        )}
      </DialogActions>
    </Box>
  );
};

TamperPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
};

TamperPanel.defaultProps = {
  children: undefined,
  value: undefined,
  index: undefined,
};

Thumbnail.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  loading: PropTypes.bool,
  imgFallback: PropTypes.func,
  onLoad: PropTypes.func,
};

Thumbnail.defaultProps = {
  title: undefined,
  imgSrc: undefined,
  loading: undefined,
  imgFallback: undefined,
  onLoad: () => {},
};

ImageRefPicker.propTypes = {
  refPickerTime: PropTypes.shape({}).isRequired,
  setPreview: PropTypes.func.isRequired,
  setRefPickerTime: PropTypes.func.isRequired,
  earliestDate: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  preview: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

ImageRefPicker.defaultProps = {
  onChange: () => {},
};

CameraTamperModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  cameraData: PropTypes.shape({
    applianceId: PropTypes.string,
    hardwareId: PropTypes.string,
    hardwareName: PropTypes.string,
    hardwareStatus: PropTypes.string,
    displayMessage: PropTypes.string,
    earliestData: PropTypes.string,
    applianceSensorNum: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    timeZone: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
  title: PropTypes.string,
};

CameraTamperModal.defaultProps = {
  children: undefined,
  value: undefined,
  index: undefined,
  title: undefined,
};

export default withSnackbar(CameraTamperModal);
